import React from "react";
import { Highlighter } from "../Highlighter/Highlighter";
import { getStigTitle, getVersionString } from "../../common/stigUtils";
import { TogglableExpander } from "../Expander/Expander";
import { Wrap } from "../Wrap";
import PropTypes from "prop-types";
import { dehighlight } from "../highlightUtils";
import './Result.scss'

function getCatClassName(check) {
  return check.cat.toLowerCase().replace(" ", "--");
}

const StigTitle = ({ result, onClick }) => (
  <Wrap
    render={children =>
      onClick ? (
        <button className="link-button" onClick={() => onClick(dehighlight(result.stig_title))}>
          {children}
        </button>
      ) : (
        children
      )
    }
  >
    <Highlighter text={getStigTitle(result.stig_title)} />
    <span className="result__version">
      ({getVersionString(result.version)})
    </span>
  </Wrap>
);

export const Result = ({
  result,
  onCardClicked,
  onStigClicked,
  showStigTitle
}) => {
  return (
    <div
      className="result"
      key={result.stigId}
      onClick={() => onCardClicked && onCardClicked(result.stigId)}
    >
      <header className="result__header">
        {showStigTitle && (
          <span className="result__stig-title">
            <StigTitle result={result} onClick={onStigClicked} />
          </span>
        )}
        <div className="result__ids">
          <Highlighter
            customClassName="result__id result__v-key"
            text={result.id}
          />
          {result.check_rule_cci && (
            <Highlighter
              customClassName="result__id result__cci"
              text={result.check_rule_cci}
            />
          )}
          {result.rule_id && (
            <Highlighter
              customClassName="result__id result__rule-id"
              text={result.rule_id}
            />
          )}
        </div>
      </header>
      <div className="result__locator">
        <p className={"result__cat result__" + getCatClassName(result)}>
          {" "}
          <span>CAT</span>{" "}
          <span className="result__cat-level">
            {result.cat.replace("CAT", "")}
          </span>
        </p>
        <h2 className="result__check-title">
          <Highlighter text={result.title} />
        </h2>
      </div>

      <TogglableExpander customCssClass="result__description">
        <Highlighter text={result.description} />
      </TogglableExpander>

      {result.check_text && (
        <div className="check-text">
          <h3>check</h3>
          <TogglableExpander>
            <Highlighter text={result.check_text} />
          </TogglableExpander>
        </div>
      )}
      <div className="fix-text">
        <h4>fix</h4>
        <TogglableExpander>
          <Highlighter text={result.fix_text} />
        </TogglableExpander>
      </div>
    </div>
  );
};

Result.propTypes = {
  result: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    fix_text: PropTypes.string,
    check_text: PropTypes.string,
    stig_title: PropTypes.string,
    score: PropTypes.string,
    cat: PropTypes.string,
    version: PropTypes.string,
    stigId: PropTypes.string
  }),
  /** function to execute when a card is clicked or undefined/null to disable clicking
   * :: id => void
   * */
  onCardClicked: PropTypes.func,
  /** function to execute when stig title is clicked or undefined/null to disable clicking
   * :: title => void
   * */
  onStigClicked: PropTypes.func,
  /** Stig title is be displayed when set to true, otherwise stig title is hidden */
  showStigTitle: PropTypes.bool
};

Result.defaultProps = {
  showStigTitle: true
};
