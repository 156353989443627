import PropTypes from "prop-types";

/**
 * Enable conditional wrapping of react elements
 */
export const Wrap = ({children, render}) => render(children);

Wrap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /** render function - :: ([node] | node) => node */
  render: PropTypes.func
};