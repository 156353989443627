import React from 'react';
import './SearchBar.scss';
import { setSearch, editSearchText } from '../../actions';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import search from '../../images/search-icon.svg';
import SelectedFilters from "../SelectedFilters/SelectedFilters";
import SearchResultCount from "../SearchResultCount/SearchResultCount";
import { Francisco } from "../Francisco/Francisco";
const noop = () => {
};

export const SearchBar = ({ editedSearchText = "", setSearch = noop, editSearchText = noop }) => {

  function handleChange(event) {
    editSearchText(event.target.value);
  }

  function handleKeyPress(e) {
    if (e.charCode === 13) {
      executeSearch(e)
    }
  }

  function executeSearch(e){
    setSearch(editedSearchText);
    //Cause the on screen keyboard to dismiss
    // in mobile by losing focus on the input
    e.target.blur()
  }

  return (

    <section className="search-start">
      <div className="francisco">
        <Francisco />
      </div>
      <div className="search-panel search-panel--floating">
        <div className="search-form">
          <input type="text" aria-label="search" className="search-form__input"
            placeholder="enter keywords to search for..."
            onKeyPress={handleKeyPress}
            onChange={handleChange}
            value={editedSearchText} />
          <button className="search-button" onClick={(e) => executeSearch(e)}>
            <img alt="search" src={search} />
          </button>
        </div>
        <SelectedFilters />
        <SearchResultCount />
      </div>
    </section>
  );
};

SearchBar.propTypes = {
  editedSearchText: PropTypes.string,
  setSearch: PropTypes.func,
  editSearchText: PropTypes.func
};

export default connect(state => ({
  editedSearchText: state.editedSearchText
}), {
    setSearch,
    editSearchText
  })(SearchBar);

