import {
  SEARCH_TAGS, SEARCH_RESULTS, TOGGLE_FILTER,
  SET_SEARCH_TEXT, EDIT_SEARCH_TEXT, SET_FILTERS,
  IS_BUSY, CLEAR_ALL_FILTERS, SCRAPE_DATE, MATCHING_STIGS, STIG_DETAILS,
  CURRENT_STIG, EDIT_STIG_FILTER_TEXT, SET_STIG_FILTER_TEXT,
  CURRENT_CHECK, SIGNUP_STARTED, SIGNUP_FAILED, SIGNUP_RESULTS, HIDE_SIGNUP
} from "../actions";

export const searchText = (state = "", action) => {
  switch (action.type) {
    case  SET_SEARCH_TEXT :
      return action.text;
    default:
      return state
  }
};

export const editedSearchText = (state = "", action) => {
  switch (action.type) {
    case EDIT_SEARCH_TEXT:
    case SET_SEARCH_TEXT:
      return action.text;
    default:
      return state;
  }
};

export const editedStigFilterText = (state = "", action) => {
    switch (action.type) {
        case EDIT_STIG_FILTER_TEXT:
        case SET_STIG_FILTER_TEXT:
            return action.text;
        default:
            return state;
    }
};

export const searchResults = (state = [], action) => {
  switch (action.type) {
    case SEARCH_RESULTS:
      return action.results;
    default:
      return state
  }
};

export const searchTags = (state = [], action) => {
  switch (action.type) {
    case SEARCH_TAGS:
      return action.tags;
    default:
      return state
  }
};

export const selectedFilters = (state = [], action) => {
  switch (action.type) {
    case TOGGLE_FILTER:
      const existingIndex = state.indexOf(action.filter);
      if (existingIndex === -1) {
        return [...state, action.filter]
      } else {
        //Slice the existing filter out of the list
        const start = state.slice(0, existingIndex);
        const end = state.slice(existingIndex + 1, state.length);
        return [...start, ...end];
      }
    case SET_FILTERS:
      return action.filters;
    case CLEAR_ALL_FILTERS:
      return [];
    default:
      return state
  }
};

export const isBusy = (state = false, action) => {
  switch (action.type){
    case IS_BUSY:
      return action.isBusy;
    default:
      return state;
  }
};

export const lastScrapeDate = (state= "",action) => {
  switch (action.type) {
    case SCRAPE_DATE:
      return action.date;
    default:
      return state;
  }
};

export const matchingStigs = (state= [],action) => {
    switch (action.type) {
        case MATCHING_STIGS:
            return action.stigs;
        default:
            return state;
    }
};


export const currentStig = (state= '',action) => {
    switch (action.type) {
        case CURRENT_STIG:
            return action.text;
        default:
            return state;
    }
};

export const currentCheck = (state= {},action) => {
  switch (action.type) {
    case CURRENT_CHECK:
      return action.check;
    default:
      return state;
  }
};

export const stigFilterText = (state= '',action) => {
    switch (action.type) {
        case SET_STIG_FILTER_TEXT:
            return action.text;
        default:
            return state;
    }
};

export const stigDetails = (state= {},action) => {
    switch (action.type) {
        case STIG_DETAILS:
            return action.results;
        default:
            return state;
    }
};

export const fetching = (state = { filteredResults: false }, action) => {
  switch (action.type) {
    case EDIT_STIG_FILTER_TEXT:
      return {
        ...state,
        filteredResults: true
      };
    case SET_STIG_FILTER_TEXT:
      return {
        ...state,
        filteredResults: false
      };

    default:
      return state;
  }
};

export const signup = (
  state = { fetching: false, complete: false, error: "" },
  action
) => {
  switch (action.type) {
    case SIGNUP_STARTED:
      return {
        ...state,
        fetching: true,
        error: ""
      };
    case SIGNUP_RESULTS: {
      return {
        ...state,
        fetching: false,
        complete: true
      };
    }
    case SIGNUP_FAILED: {
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    }
    case HIDE_SIGNUP: {
      return {
        ...state,
        error: "",
        complete: false
      };
    }
    default:
      return state;
  }
};