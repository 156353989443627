import React from 'react';
import './BusyIndicator.css';
import { connect } from 'react-redux';
import PropTypes from "prop-types";



export const BusyIndicator = ({show = false}) => {
  if (show === true) {
      return (
        <div className="busy-indicator__overlay">
        <div className="busy-indicator__container">
          <div className="busy-indicator__double-bounce1"/>
          <div className="busy-indicator__double-bounce2"/>
        </div>
        <div className="busy-indicator__message">Please wait...</div>
      </div>
      );
    }else{
      return <div/>;
    }
};

BusyIndicator.propTypes = {
  show : PropTypes.bool
};

export default connect(state => ( { show : state.isBusy }))(BusyIndicator);