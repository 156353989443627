import React, { Component } from 'react';

function getDisplayName(Component) {
  return (
    Component.displayName ||
    Component.name ||
    (typeof Component === 'string' && Component.length > 0 ? Component : 'Unknown')
  );
}

/**
 * This is a Higher Order Component that captures when a mouse is hovering over the
 * component and sets the isHovered prop on the wrapped component
 * @param WrappedComponent
 */
export function withHover(WrappedComponent) {
  return class extends Component {
    static displayName = `withHover(${getDisplayName(WrappedComponent)})`;

    state = {
      isHovered: false,
    };

    onMouseEnter = () => {
      this.setState({ isHovered: true });
    };

    onMouseLeave = () => {
      this.setState({ isHovered: false });
    };

    render() {
      return <div
        onMouseEnter={ this.onMouseEnter }
        onMouseLeave={ this.onMouseLeave }>
        <WrappedComponent
          { ...this.props }
          isHovered={ this.state.isHovered }
        />
      </div>;
    }
  };
}