import "./CatFilter.css";
import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "../Checkbox/Checkbox";
import { withHover } from "../withHover";
import noop from "lodash/fp/noop";

export const CatFilter = ({
  isSelected = false,
  cat = "",
  toggleFilter = noop,
  isHovered = false
}) => {
  function applyFilter(e, selectedFilter) {
    toggleFilter(selectedFilter);
  }

  return (
    <li
      className={`cat-filter ${isSelected ? "selected" : ""}`}
      data-category={cat}
      onClick={e => applyFilter(e, cat)}
    >
      <div className="cat-filter__checkbox">
        <Checkbox isHovered={isHovered} isChecked={isSelected} />
      </div>
      <div className="cat-filter__name">{cat}</div>
    </li>
  );
};

CatFilter.propTypes = {
  isSelected: PropTypes.bool,
  cat: PropTypes.string,
  toggleFilter: PropTypes.func
};

export default withHover(CatFilter);
