export const getStigTitle = title =>
  (title || "")
    .replace("Security Technical Implementation Guide", "STIG")
    .replace("SECURITY TECHNICAL IMPLEMENTATION GUIDE", "STIG")
    .replace("Security Implementation Guide", "STIG");

export const getTitleNoStig = title => getStigTitle(title).replace("STIG", "");

export const getVersionString = (version = "") => {
  const benchmark = "Benchmark";
  return version
    .replace("Version", "ver")
    .replace("Release", "rel")
    .substring(0, version.indexOf(benchmark) - benchmark.length);
};
