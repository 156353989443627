import React from "react";
import map from "lodash/fp/map";
import split from "lodash/fp/split";
import isString from "lodash/fp/isString";
import compose from "lodash/fp/compose";
import compact from "lodash/fp/compact";
import flatten from "lodash/fp/flatten";
const mapWithIndex = map.convert({ cap: false });

const when = (pred, f) => x => pred(x) ? f(x) : x;
const isEven = x => x % 2 === 0;

// :: String => [ String | Component ]
export const toLines = compose(
  compact,
  mapWithIndex((x, idx) => isEven(idx) ? x : <br/>),
  split(/(\r?\n|<br\/>|<br \/>)/)
);

// :: String => [ String | Component ]
export const highlightTokens = compose(
  compact,
  mapWithIndex((x, idx) => isEven(idx) ? x : <Highlight>{ x }</Highlight>),
  split(/####(.*?)&&&&/)
);

export const Highlight = ({ children }) => (
  <span className="highlight">{ children }</span>
);

export const Highlighter = ({ text = "", customClassName = "" }) => {
  return (
    <div className={`highlighter ${customClassName}`}>
      {
        compose(
          mapWithIndex((x, idx) => <React.Fragment key={ idx }>{x}</React.Fragment>),
          flatten,
          map(when(isString, highlightTokens)),
          toLines
        )(text)
      }
    </div>
  );
};