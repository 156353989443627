import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash/fp";
import "./StigResults.scss";
import classnames from "classnames";
import { Result } from "../Result/Result";

export class StigResults extends React.PureComponent {
  render() {
    const {
      results = [],
      fetching = false,
      setCurrentCheck,
      showCompact
    } = this.props;

    return (
      <section
        className={classnames("stig-results", {
          "stig-results--loading": fetching
        })}
      >
        <div>
          {map(
            r => (
              <Result
                key={r.stigId}
                result={r}
                onCardClicked={showCompact && setCurrentCheck}
                showStigTitle={false}
              />
            ),
            results
          )}
        </div>
      </section>
    );
  }
}

StigResults.propTypes = {
  stigFilterText: PropTypes.string,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      fix_text: PropTypes.string,
      check_text: PropTypes.string,
      stig_title: PropTypes.string,
      score: PropTypes.string,
      cat: PropTypes.string,
      version: PropTypes.string,
      check_rule_cci: PropTypes.string,
      rule_id: PropTypes.string
    })
  ),
  fetching: PropTypes.bool,
  setCurrentCheck: PropTypes.func,
  /** True if the results should be shown using compact layout */
  showCompact: PropTypes.bool
};
