import React from 'react';
import './Home.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import search from '../../images/search-icon.svg';
import { editSearchText, setSearch } from "../../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import About from "../About/About";
import { Francisco } from "../Francisco/Francisco";
import Newsletter from "../Newsletter/Newsletter";

const noop = () => {
};

export const Home = ({ editedSearchText = "", setSearch = noop, editSearchText = noop,
                       showAbout = false, showSignup = false }) => {

  function handleChange(event) {
    editSearchText(event.target.value);
  }

  function handleKeyPress(event) {
    if (event.charCode === 13) {
      setSearch(editedSearchText);
    }
  }

  return (
    <div
      className="home-page">
      <div className="home-page__header">
        <Header showAboutMenu={true} showSignupMenu={true} />
      </div>
      <main className="home-page__main">
        <section className="home-page__search-start">
          <About />
          <Newsletter />
          <div className="francisco-big">
            <Francisco />
          </div>
          <div className="home-page__search-panel">
            <h1 className="couplet">
              <span className="couplet__line">Find the <em>DoD security guidance</em></span>
              <span
                className="couplet__line">for your <em>government IT compliance</em></span>
            </h1>
            <div className="search-form">
              <input type="text" aria-label="search" placeholder="enter keywords to search for..."
                onKeyPress={handleKeyPress}
                onChange={handleChange}
                value={editedSearchText} />
              <button className="search-button" onClick={() => setSearch(editedSearchText)}>
                <img alt="search" src={search} />
              </button>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

Home.propTypes = {
  editedSearchText: PropTypes.string,
  setSearch: PropTypes.func,
  editSearchText: PropTypes.func,
  showAbout: PropTypes.bool, // should the about popup be displayed
  showSignup: PropTypes.bool // should the signup popup be displayed
};


export default connect(state => ({
  editedSearchText: state.editedSearchText,
}), {
    setSearch,
    editSearchText,
  })(Home);
