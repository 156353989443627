import _ from "lodash/fp";
import {
  CURRENT_STIG,
  fetchCurrentStig,
  search,
  SET_FILTERS,
  SET_SEARCH_TEXT,
  SET_STIG_FILTER_TEXT
} from "./actions";

function getQueryVariable(variable, query) {
  if (query) {
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      pair[0] = pair[0].replace('?', '')
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }
}

export const dispatchActionForUrl = store => () => {
  const { router, searchText, stigFilterText, currentStig, selectedFilters } = store.getState();

  // :: ({ pathname }) => bool
  const isSearchUrl = _.compose(
    _.includes("search"),
    _.get("pathname")
  );
  const isStigUrl = _.compose(
    _.includes("stig"),
    _.get("pathname")
  );

  const pathParts = _.compact(
    router.location.pathname.replace(/search/i, "").split("/")
  );
  const searchPath = decodeURI(pathParts[0]);
  const filtersPath = pathParts[1] && pathParts[1].split(",");

  const stigPathParts = _.compact(
    router.location.pathname.replace(/search/i, "").split("/")
  );

  const stigTitle = decodeURIComponent(stigPathParts[1]);
  const stigQueryText = getQueryVariable('query', router.location.search);
  const stigFilters = getQueryVariable('filters', router.location.search)

  const decodedFilters = _.map(x => {
    return decodeURI(x
        .replace(/-/g, '/')
        .replace(/\|/g, ','));
  }, filtersPath);

  if (
    isSearchUrl(router.location) &&
    searchPath &&
    searchPath !== "undefined" &&
    searchText !== searchPath
  ) {
    store.dispatch({ type: SET_SEARCH_TEXT, text: searchPath });

    if (filtersPath && !_.isEqual(selectedFilters, decodedFilters)) {
      store.dispatch({ type: SET_FILTERS, filters: decodedFilters });
    }

    store.dispatch(search(searchPath, decodedFilters));
  } else if (isStigUrl(router.location) &&
    (stigTitle !== currentStig
      || (stigQueryText !== undefined && stigQueryText !== stigFilterText))) {

    if (stigTitle !== currentStig) {
      store.dispatch({ type: CURRENT_STIG, text: stigTitle });
    }

    let decodedStigFilters = undefined
    if (stigFilters) {
      decodedStigFilters = _.map(x => {
        return decodeURI(x.replace("-", "/"));
      }, stigFilters.split(','));

      if (!_.isEqual(selectedFilters, decodedStigFilters)) {
        store.dispatch({ type: SET_FILTERS, filters: decodedStigFilters });
      }
    }
    if (stigQueryText !== undefined && stigQueryText !== stigFilterText) {
      store.dispatch({ type: SET_STIG_FILTER_TEXT, text: stigQueryText });
    }

    if (stigQueryText !== undefined && !_.isEqual(stigQueryText, stigFilterText)
      || stigTitle !== currentStig
      || decodedStigFilters && !_.isEqual(selectedFilters, decodedStigFilters)) {
      store.dispatch(fetchCurrentStig(stigTitle, stigQueryText, decodedStigFilters));
    }
  } else if (
    searchText !== "" &&
    searchText !== searchPath &&
    (!searchPath || searchPath === "undefined")
  ) {
    //Clear the search
    store.dispatch({ type: SET_SEARCH_TEXT, text: '' });
    store.dispatch({ type: SET_FILTERS, filters: [] });
  }
};
