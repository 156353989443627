import React from "react";
import PropTypes from "prop-types";
import { throttle, noop, get } from "lodash/fp";
import classnames from "classnames";

const debug = require("debug")("components:ScrollableContainer");

/**
 * Is the scrollable element currently scrolled to its bottom-most point
 * @param element
 * @returns {boolean}
 */
function endOfScroll(element) {
  return element.scrollHeight - element.scrollTop === element.clientHeight;
}

/**
 * Is the scrollable element currently scrolled to its top-most point
 * @param element
 * @returns {boolean}
 */
function topOfScroll(element) {
  return element.scrollTop === 0;
}

/**
 * Is the content overflowing the element such that it can be scrolled
 * @param element
 * @returns {boolean}
 */
function isScrollable(element) {
  return element.scrollHeight > element.clientHeight;
}

/**
 * The ScrollContainer provides enhanced capabilities for elements that scroll
 *  including:
 *  - notifying the parent of scroll position via the onScroll prop
 *  - avoiding the iOS scrolling bug by preventing drag at top and bottom
 */
export class ScrollContainer extends React.Component {
  constructor(props) {
    super(props);
    this.throttledOnScroll = throttle(16, this.onScroll);
    this.lastClientY = 0;
  }

  handleTouchStart = event => {
    if (!isScrollable(this.node)) {
      debug("handleTouchStart: element is not scrollable");
      return;
    }
    this.lastClientY = get("targetTouches[0].clientY", event);
  };

  handleTouchMove = event => {
    if (!isScrollable(this.node)) {
      debug("handleTouchMove: element is not scrollable");
      return;
    }

    const currentClientY = get("changedTouches[0].clientY", event);
    const delta = currentClientY - this.lastClientY;

    debug({ lastClientY: this.lastClientY, currentClientY, delta });

    if (topOfScroll(this.node) && delta > 0) {
      debug("handleTouchMove: at top and scrolling down prevented");
      event.preventDefault();
    } else if (endOfScroll(this.node) && delta < 0) {
      debug("handleTouchMove: at bottom and scrolling up prevented");
      event.preventDefault();
    } else {
      debug("handleTouchMove: scrolling...")
    }

    this.lastClientY = currentClientY;
  };

  componentDidMount() {
    this.node.addEventListener("scroll", this.throttledOnScroll);
    this.node.addEventListener("touchstart", this.handleTouchStart);
    this.node.addEventListener("touchmove", this.handleTouchMove);
  }

  componentWillUnmount() {
    this.node.removeEventListener("scroll", this.throttledOnScroll);
    this.node.removeEventListener("touchstart", this.handleTouchStart);
    this.node.removeEventListener("touchmove", this.handleTouchMove);
  }

  onScroll = () => {
    if (!isScrollable(this.node)) {
      debug("onScroll: element is not scrollable");
      return;
    }
    (this.props.onScroll || noop)(this.node.scrollTop);
  };

  render() {
    return (
      <div
        ref={node => {
          this.node = node;
        }}
        className={classnames("scroll-container", this.props.customCssClass)}
      >
        {this.props.children}
      </div>
    );
  }
}

ScrollContainer.propTypes = {
  customCssClass: PropTypes.string
};
