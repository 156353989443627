import React from 'react';
import PropTypes from "prop-types";
import './Header.scss';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { showSignup, showAbout } from "../../reducers/rootReducer";


const Header = ({ showAboutMenu = false , showSignupMenu = false, showingAbout = false, showingSignup = false}) => (
  <header className="app-header">
    <h1 className="app-header__brand">
      <Link to="/">
        STIGHUB
      </Link>
    </h1>
    <nav>
      <ul className="app-header__menu">
        {
          showAboutMenu &&
          <li className="app-header__menu-item">

            {  (showingAbout || showingSignup) && <label>about</label> }
            { !showingAbout && !showingSignup && <Link to="/about">about</Link> }
          </li>
        }
        { showSignupMenu &&
          <li className="app-header__menu-item">
            { (showingSignup || showingAbout)&& <label>signup</label> }
            { !showingSignup  && !showingAbout && <Link to="/signup">signup</Link> }
          </li>
        }
      </ul>
    </nav>
  </header>
);

Header.propTypes = {
  showAboutMenu: PropTypes.bool,
  showSignupMenu: PropTypes.bool
}

export default connect(
  state => ({
    showingSignup: showSignup(state),
    showingAbout: showAbout(state)
  }))(Header)
//export default Header;
