// List of coarse application viewport breakpoints
//  the string values for the Enum aren't intended to be used programmatically
export const Breakpoints = {
  SMALL: "<600",
  LARGE: ">600"
};

// :: { width } => String
export const breakpoint = size =>
  (size && size.width < 600)
    ? Breakpoints.SMALL
    : Breakpoints.LARGE;