import React, { Component } from "react";
import { connect } from "react-redux";
import "./CheckDetailsPage.scss";
import { Highlighter } from "../Highlighter/Highlighter";
import { getStigTitle, getVersionString } from "../../common/stigUtils";
import noop from "lodash/fp/noop";
import get from "lodash/fp/get";
import compose from "lodash/fp/compose";
import split from "lodash/fp/split";
import last from "lodash/fp/last";
import size from "lodash/fp/size";
import { getSelectedCheckId } from "../../reducers/rootReducer";
import { fetchCurrentCheck, setCurrentStig } from "../../actions";
import classnames from "classnames";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";
import { dehighlight } from "../highlightUtils";


// :: { cat } => Number
// e.g. CAT II => 2
const catSeverity = compose(size, last, split(" "), get("cat"));

export class CheckDetailsPage extends Component {

  componentDidMount() {
    (this.props.fetchCurrentCheck || noop)(this.props.selectedCheckId)
  }

  render() {
    let { result, setCurrentStig, searchText } = this.props;

    function onTitleClicked(title) {
      const cleanTitle = dehighlight(title)
      setCurrentStig(cleanTitle);
    }

    return (
      <div className="check-details-page">
        <div className="check-header">
          <Link to={`/search/${searchText}`} className="check-header__back">
            {`< Search`}
          </Link>
          <div className="check-header__title">
            <div
              className={classnames("check-header__cat", {
                "check-header__cat--i": catSeverity(result) === 1,
                "check-header__cat--ii": catSeverity(result) === 2,
                "check-header__cat--iii": catSeverity(result) === 3
              })}
            />
            <span className="check-header__vkey">{dehighlight(result.id)}</span>
          </div>
          <span/>
        </div>
        <div className="check-details">
          <div className="check__stig-title">
            <button className="link-button" onClick={() => onTitleClicked(result.stig_title)}>
              <Highlighter text={getStigTitle(result.stig_title)}/>
              <span className="result__version">
              ({getVersionString(result.version)})
            </span>
            </button>
          </div>
          <h2 className="check__title">
            <Highlighter text={result.title}/>
          </h2>

          <div className="check__description">
            <Highlighter text={result.description}/>
          </div>
          {result.check_text && (
            <div className="check__check-text">
              <h3>check</h3>
              <Highlighter text={result.check_text}/>
            </div>
          )}
          {result.check_text && (
            <div className="check__fix-text">
              <h3>fix</h3>
              <Highlighter text={result.fix_text}/>
            </div>
          )}
          <dl className="check__metadata">
            <dt>Finding id:</dt>
            <dd><Highlighter text={result.id} /></dd>
            <dt>Rule id:</dt>
            <dd>{result.rule_id}</dd>
            <dt>Control id:</dt>
            <dd>{result.check_rule_cci}</dd>
            <dt>Severity:</dt>
            <dd>
              {result.cat}
              {/* TODO: clean this up*/}
              <div className={classnames("check__cat", {
                "check__cat--i": catSeverity(result) === 1,
                "check__cat--ii": catSeverity(result) === 2,
                "check__cat--iii": catSeverity(result) === 3
              })}
              />
            </dd>
          </dl>
        </div>
      </div>
    );
  }
}

CheckDetailsPage.propTypes = {
  result: PropTypes.shape({}),
  setCurrentStig: PropTypes.func,
  searchText: PropTypes.string,
  selectedCheckId: PropTypes.string,
  fetchCurrentCheck: PropTypes.func
}

CheckDetailsPage.defaultProps = { result: {}, setCurrentStig: noop }

export default connect(
  state => ({
    result: state.currentCheck,
    searchText: state.searchText,
    selectedCheckId: getSelectedCheckId(state)
  }),
  {
    setCurrentStig,
    fetchCurrentCheck
  }
)(CheckDetailsPage);