import React from "react";
import "./HexFrame.scss";

const HexFrame = () => {
  return (
    <svg viewBox="0 0 1260.432 1143.72" className="hex-frame">
      <defs>
        <filter id="hex-frame__dropshadow" x="0" y="0" width="1260.432"
                height="1143.72" filterUnits="userSpaceOnUse">
          <feOffset dx="4" dy="5" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="9" result="blur" />
          <feFlood floodOpacity="0.537" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <path
        d="M308.795-207.434a57.867,57.867,0,0,0-58.686-.367Q61.815-99.095-126.149,9.559a58.02,58.02,0,0,0-33.925,52.8v474.4a85.493,85.493,0,0,0,.708,11.06,57.708,57.708,0,0,0,28.531,42.839q190.184,109.8,380.3,219.684a57.859,57.859,0,0,0,58.682.386q188.3-108.718,376.258-217.375a58.023,58.023,0,0,0,33.925-52.8V70.921c0-6.575-.011-12.676-1.241-19.235a57.671,57.671,0,0,0-28-39.428Q498.914-97.539,308.795-207.434"
        transform="translate(857.16 227.07) rotate(77)" />
    </svg>

  )
};


export default HexFrame