import './SelectedFilters.scss';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toggleFilter, clearAllFilters } from '../../actions';

const noop = () => { };

export const SelectedFilters = ({ filters = [], toggleFilter = noop, clearAllFilters = noop }) => {


  function applyFilter(e, selectedFilter) {
    toggleFilter(selectedFilter);
  }


  function clearFilters(e) {
    clearAllFilters();
  }

  const results = filters.map(filter => {
    return (
      <li className="selected-filters__item" onClick={(e) => applyFilter(e, filter)} key={filter}>
        {filter}
      </li>
    );
  });

  return filters.length > 0 &&
    <div className="selected-filters">
      <ul className="selected-filters__list">
        <li>Current filters</li>
        {results}
        {results.length > 1 && <span onClick={(e) => clearFilters(e)} className="selected-filters__clear">clear all</span>}
      </ul>

    </div>;
};

SelectedFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string),
  clearAllFilters: PropTypes.func,
  toggleFilter: PropTypes.func
};


export default connect(state => ({ filters: state.selectedFilters }), ({ toggleFilter, clearAllFilters }))(SelectedFilters);

