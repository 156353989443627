import './TagFilter.css';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toggleFilter } from "../../actions";
import { Checkbox } from "../Checkbox/Checkbox";
import { withHover } from "../withHover";
import noop from "lodash/fp/noop";

export const TagFilter = ({
  isSelected = false,
  tag = '',
  toggleFilter = noop,
  isHovered = false
}) => {

  function applyFilter(e, selectedFilter) {
    toggleFilter(selectedFilter);
  }


  return (
    <li className={`tag-filter ${isSelected ? "selected" : ''}`}
      onClick={(e) => applyFilter(e, tag)}>
      <div className="tag-filter__checkbox">
        <Checkbox isHovered={isHovered} isChecked={isSelected} />
      </div>
      <div className="tag-filter__name">{tag}</div>
    </li>
  );
};

TagFilter.propTypes = {
  isSelected: PropTypes.bool,
  tag: PropTypes.string,
  toggleFilter: PropTypes.func
};

export const HoverableTagFilter = withHover(TagFilter);

export default connect(state => (state), { toggleFilter: toggleFilter })(HoverableTagFilter);
