import React from 'react';
import './Privacy.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import francisco from '../../images/francisco-slate.svg';

export default () => {
  return (
    <div className="app-page">
      <header className="app-page__header">
        <Header/>
        <section className="search-start">
          <div className="francisco">
            <img alt="francisco" src={francisco}/>
          </div>
        </section>
      </header>
      <main className="app-page__main">
        <section className="static-content">
        <div>
            <div>
              <h2>Privacy Policy of stighub.com</h2>

              <p>This Application collects some Personal Data from its Users.</p>

              <h5>Data Controller and Owner</h5>

              <p>Beacon Cloud Solutions, 13771 Danielson St suite c, Poway, CA 92064</p>
              <a href="mailto:info@beaconcloudsolutions.com">info@beaconcloudsolutions.com</a>

              <h5>Types of Data collected</h5>

              <p>Among the types of Personal Data that this Application collects, by itself or through third parties, there are: First Name, Last Name, Company
                name and Email.</p>

              <p>Other Personal Data collected may be described in other sections of this privacy policy or by dedicated explanation text contextually with the
                Data collection.</p>
                <p> The Personal Data may be freely provided by the User, or collected automatically when using this Application.
                </p> <p> Any use of Cookies - or of other tracking tools - by this Application or by the owners of third party services used by this Application,
                    unless stated otherwise, serves to identify Users and remember their preferences, for the sole purpose
                    of providing the service required by the User.
            </p>
              <p>
              Failure to provide certain Personal Data may make it impossible for this Application to provide its services.
              </p>

              <p>The User assumes responsibility for the Personal Data of third parties published or shared through this Application and declares to have the
                right to communicate or broadcast them, thus relieving the Data Controller of all responsibility.
              </p>

              <h5>Mode and place of processing the Data</h5>

              <h5>Methods of processing</h5>

              <p>The Data Controller processes the Data of Users in a proper manner and shall take appropriate security measures to prevent unauthorized access,
                disclosure, modification, or unauthorized destruction of the Data.</p>
              <p>
                The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to
                the purposes indicated. In addition to the Data Controller, in some cases, the Data may be accessible to certain
                types of persons in charge, involved with the operation of the site (administration, sales, marketing, legal, system administration) or external
                parties (such as third party technical service providers, mail carriers, hosting providers,
                IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be
                requested from the Data Controller at any time.</p>

              <h5>Place</h5>

              <p>The Data is processed at the Data Controller's hosted Infrastructure-as-a-Service provider data centers and in any other places where the
                parties involved with the processing are located. For further information, please contact the Data Controller.</p>

              <h5>Retention time</h5>

              <p>The Data is kept for the time necessary to provide the service requested by the User, or stated by the purposes outlined in this document, and
                the User can always request that the Data Controller suspend or remove the data.</p>

              <h5>The use of the collected Data</h5>

              <p>The Data concerning the User is collected to allow the Application to provide its services, as well as for the following purposes: Contacting
                the User.</p>

              <p>The Personal Data used for each purpose is outlined in the specific sections of this document.</p>

              <h5>Detailed information on the processing of Personal Data
              </h5>

              <p>Personal Data is collected for the following purposes and using the following services:</p>

              <h5>Contacting the User</h5>

              <h5>Contact form (This Application)</h5>

              <p>By filling in the contact form with their Data, the Users authorizes this Application to use these details to reply to requests for
                information, quotes or any other kind of request as indicated by the form’s header.</p>

              <p>Personal Data collected: Company name, Email, First Name and Last Name.</p>

              <h5>Additional information about Data collection and processing
              </h5>

              <h5>Legal action</h5>

              <p>The User's Personal Data may be used for legal purposes by the Data Controller, in Court or in the stages leading to possible legal action
                arising from improper use of this Application or the related services.</p>
              <p>
                The User is aware of the fact that the Data Controller may be required to reveal personal data upon request of public authorities.
              </p>

              <h5>Additional information about User's Personal Data</h5>

              <p>In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual
                information concerning particular services or the collection and processing of Personal Data upon request.</p>

              <h5>System Logs and Maintenance</h5>

              <p>For operation and maintenance purposes, this Application and any third party services may collect files that record interaction with this
                Application (System Logs) or use for this purpose other Personal Data (such as IP Address).</p>

              <h5>Information not contained in this policy</h5>

              <p>More details concerning the collection or processing of Personal Data may be requested from the Data Controller at any time. Please see the
                contact information at the beginning of this document.</p>

              <h5>The rights of Users</h5>

              <p>Users have the right, at any time, to know whether their Personal Data has been stored and can consult the Data Controller to learn about their
                contents and origin, to verify their accuracy or to ask for them to be supplemented, cancelled,
                updated or corrected, or for their transformation into anonymous format or to block any data held in violation of the law, as well as to oppose
                their treatment for any and all legitimate reasons. Requests should be sent to the Data Controller
                at the contact information set out above.</p>

              <p>This Application does not support “Do Not Track” requests.To determine whether any of the third party services it uses honor the “Do Not Track”
                requests, please read their privacy policies.</p>

              <h5>Changes to this privacy policy</h5>

              <p>The Data Controller reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page. It is
                strongly recommended to check this page often, referring to the date of the last modification listed
                at the bottom. If a User objects to any of the changes to the Policy, the User must cease using this Application and can request that the Data
                Controller erase the Personal Data. Unless stated otherwise, the then-current privacy policy applies
                to all Personal Data the Data Controller has about Users.</p>
            </div>
          </div>
        </section>
      </main>
      <Footer/>
    </div>
  );
};

