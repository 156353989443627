import fetch from 'cross-fetch';
import _ from 'lodash';
import { push } from 'connected-react-router'
import moment from 'moment'
import { getTitleNoStig } from '../common/stigUtils'
import find from "lodash/fp/find";

const { getOr, get } = require("lodash/fp");

export const SEARCH = 'SEARCH';
export const SEARCH_TAGS = 'SEARCH_TAGS';
export const SEARCH_CATS = 'SEARCH_CATS';
export const SEARCH_STARTED = 'SEARCH_STARTED';
export const SEARCH_FAILED = 'SEARCH_FAILED';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const MATCHING_STIGS = 'MATCHING_STIGS';
export const STIG_DETAILS = 'STIG_DETAILS';
export const CURRENT_STIG = 'CURRENT_STIG';
export const CURRENT_CHECK = 'CURRENT_CHECK';
export const STIG_FILTER_TEXT = 'STIG_FILTER_TEXT';

export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const EDIT_SEARCH_TEXT = 'EDIT_SEARCH_TEXT';

export const SET_STIG_FILTER_TEXT = 'SET_STIG_FILTER_TEXT';
export const EDIT_STIG_FILTER_TEXT = 'EDIT_STIG_FILTER_TEXT';

export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const SET_FILTERS = 'SET_FILTERS';
export const SCRAPE_DATE = 'SCRAPE_DATE';

export const IS_BUSY = 'IS_BUSY';

export const HIDE_ABOUT = 'HIDE_ABOUT';
export const HIDE_SIGNUP = 'HIDE_SIGNUP';
export const SIGNUP_STARTED = 'SIGNUP_STARTED';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SIGNUP_RESULTS = 'SIGNUP_RESULTS';

const SEARCH_URL_FRAGMENT = '/search';
const STIG_URL_FRAGMENT = '/stig';

const protocol = getOr("https:", "location.protocol", window);
let api_path = `${protocol}//${process.env.REACT_APP_BASE_API_URL}`;

export const search = (text, filters, reloadFilters = true) => (dispatch) => {
  dispatch(setIsBusy(true));
  dispatch(searchStarted());

  const filtersWithoutCommas = _.map(filters, x => x.replace(new RegExp(',', 'g'), '|'));
  const joinedFilters = _.join(filtersWithoutCommas, ',');

  return fetch(`${api_path}/api/search?searchTerm=${text}&start=0&filters=${joinedFilters}`)
    .then(response => response.json(), error => dispatch(searchFailed(error)))
    .then(json => {

      dispatch(searchCompleted(text, json.results || []));
      dispatch(matchingStigs(json.stigs || []));

      if (reloadFilters) {
        dispatch(searchTags(json.tags || []));
      }

      dispatch(searchCats(json.cats || []));

      dispatch(setIsBusy(false));
    });
};

export const getLastScrapeDate = () => (dispatch) => {
  return fetch(`${api_path}/api/scrape-date`)
    .then(response => response.text())
    .then(res => dispatch({ type: SCRAPE_DATE, date: moment.utc(res).fromNow() }));
};

//TODO: Can this move to some conventions where started, failed, completed are always there for async actions.
//TODO: use flux standard action format (payload and optional error)
export const searchStarted = () => ({ type: SEARCH_STARTED });
export const editSearchText = text => ({ type: EDIT_SEARCH_TEXT, text });
export const searchTags = tags => ({ type: SEARCH_TAGS, tags });
export const searchCats = cats => ({ type: SEARCH_CATS, cats });
export const searchFailed = error => ({ type: SEARCH_FAILED, error });
export const searchCompleted = (text, results) => ({ type: SEARCH_RESULTS, text, results });
export const matchingStigs = stigs => ({ type: MATCHING_STIGS, stigs });
export const stigDetails = results => ({ type: STIG_DETAILS, results });
export const currentStig = text => ({ type: CURRENT_STIG, text });
export const currentCheck = check => ({ type: CURRENT_CHECK, check });

function getURISafeFilters(filters) {
  return _.map(filters, (x) => {
    // TODO: consider using encodeURIComponent instead
    return encodeURI(x
        .replace(/\//g, '-')
        .replace(/,/g, '|')
    );
  });
}

function getURISafeSearchTerm(text) {
  return encodeURIComponent(text);
}

export const toggleFilter = filter => (dispatch, getState) => {
  dispatch({ type: TOGGLE_FILTER, filter });
  const filterList = _.join(getURISafeFilters(getState().selectedFilters), ',');

  dispatch(push(`${SEARCH_URL_FRAGMENT}/${getURISafeSearchTerm(getState().searchText)}/${filterList}`));
  dispatch(search(getState().searchText, getState().selectedFilters, false))

  //Make sure to reset the edited search text back when toggling filters. Makes sure the search bar text matches the search results
  dispatch(editSearchText(getState().searchText))
};

export const toggleCatFilter = filter => (dispatch, getState) => {
  dispatch({ type: TOGGLE_FILTER, filter });
  const filterList = _.join(getURISafeFilters(getState().selectedFilters), ',');

  dispatch(push(`${STIG_URL_FRAGMENT}/${getURISafeSearchTerm(getState().currentStig)}?query=${getState().stigFilterText}&filters=${filterList}`));
  dispatch(fetchCurrentStig(getState().currentStig, getState().stigFilterText, getState().selectedFilters))

  //Make sure to reset the edited search text back when toggling filters. Makes sure the search bar text matches the search results
  dispatch(editStigFilterText(getState().stigFilterText))
};


export const clearAllFilters = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_ALL_FILTERS });

  dispatch(push(`${SEARCH_URL_FRAGMENT}/${getURISafeSearchTerm(getState().searchText)}/`));
  dispatch(search(getState().searchText, [], false))

  //Make sure to reset the edited search text back when toggling filters. Makes sure the search bar text matches the search results
  dispatch(editSearchText(getState().searchText))
};

export const setSearch = text => (dispatch, getState) => {
  if (text && text !== '') {
    const filterList = _.join(getURISafeFilters(getState().selectedFilters), ',');
    dispatch(push(`${SEARCH_URL_FRAGMENT}/${getURISafeSearchTerm(text)}/${filterList}`));
  }
};

export const editStigFilterText = text => dispatch => {
  dispatch({ type: EDIT_STIG_FILTER_TEXT, text });
};

export const setStigFilterText = text => (dispatch, getState) => {
  const filterList = _.join(getURISafeFilters(getState().selectedFilters), ',');
  dispatch(push(`${STIG_URL_FRAGMENT}/${getURISafeSearchTerm(getState().currentStig)}?query=${text}&filters=${filterList}`));
}

export const setCurrentStig = title => (dispatch) => {
  if (title && title !== '') {

    dispatch(push(`/stig/${getURISafeSearchTerm(title)}`));
    dispatch(editStigFilterText(''))
  }
};

export const setCurrentCheck = checkId => dispatch => {
  dispatch(push(`/check/${ checkId }`));
};

export const fetchCurrentStig = (title, query, filters) => (dispatch) => {
  dispatch(setIsBusy(true));
  dispatch(stigDetails({}));

  if (query) {
    const filtersWithoutCommas = _.map(filters, x => x.replace(',', '|'), ',');
    filtersWithoutCommas.push(getTitleNoStig(title));
    const joinedFilters = _.join(filtersWithoutCommas, ',');

    //We need to do normal search call but move the title into a filter
    fetch(`${api_path}/api/search?searchTerm=${query}&start=0&filters=${joinedFilters}`)
      .then(response => response.json(), error => dispatch(searchFailed(error)))
      .then(json => {
        dispatch(stigDetails(json || {}));
        dispatch(setIsBusy(false));
      });

  } else {
    fetch(`${api_path}/api/find-stig-by-title?title=${title}&filters=${filters}`)
      .then(response => response.json(), error => dispatch(searchFailed(error)))
      .then(json => {
        dispatch(stigDetails(json || {}));
        dispatch(setIsBusy(false));
      });
  }

}


export const fetchCurrentCheck = (stigId) => (dispatch,getState)=> {
  dispatch(setIsBusy(true));

  if (getState().currentCheck.stig_id === stigId)
    return

  let currentCheckData = find({ stigId }, getState().searchResults)
    || find({ stigId }, get('stigDetails.results', getState()));

  if (currentCheckData){
    dispatch(currentCheck(currentCheckData));
    dispatch(setIsBusy(false));
    return
  }

  fetch(`${api_path}/api/find-check-by-id?checkId=${stigId}`)
    .then(response => response.json(), error => dispatch(searchFailed(error)))
    .then(json => {
      dispatch(currentCheck(json || {}));
      dispatch(setIsBusy(false));
    });
}

export const setIsBusy = isBusy => ({ type: IS_BUSY, isBusy });

export const hideAbout = () => dispatch => {
  dispatch({ type: HIDE_ABOUT });
  dispatch(push('/'));
};

export const hideSignup = () => dispatch => {
  dispatch({ type: HIDE_SIGNUP });
  dispatch(push('/'));
};

function isRunningStandalone() {
  return (window.matchMedia('(display-mode: standalone)').matches || navigator.standalone || false);
}

export const signup = (name, email) => dispatch => {
  dispatch({ type: SIGNUP_STARTED });

  fetch(`${ api_path }/newsletter`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      name: name,
      email: email,
      isFromApp: isRunningStandalone()
    })
  })
  // TODO: refactor this into reusable fetch handler
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText); // TODO: capture actual error?
      }
      return response;
    })
    .then(
      response => dispatch({ type: SIGNUP_RESULTS, results: response.json() }),
      err => dispatch({ type: SIGNUP_FAILED, error: get('message', err)})
    );
};
