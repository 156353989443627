import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types';
import * as serviceWorker from './registerServiceWorker'
import { Provider } from 'react-redux'

import { ConnectedRouter } from 'connected-react-router'

import './index.css';
import App from './components/App/App'
import Home from './components/Home/Home'
import Privacy from './components/Privacy/Privacy'
import Terms from './components/Terms/Terms'

import withTracker from './withTracker'
import StigDetailsPage from './components/StigDetailsPage/StigDetailsPage'
import Search from './components/Search/Search'
import { dispatchActionForUrl } from "./urlUtils";

import { getLastScrapeDate } from "./actions";

import configureStore from "./configureStore";
import CheckDetailsPage from "./components/CheckDetailsPage/CheckDetailsPage";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory()

const store = configureStore({}, history);
store.subscribe(dispatchActionForUrl(store));

store.dispatch(getLastScrapeDate());

const Root = ({ store }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact={true} path="/privacy" component={withTracker(Privacy)} />
        <Route exact={true} path="/terms" component={withTracker(Terms)} />
        <Route path="/about/" component={withTracker(Home)} />
        <Route path="/signup/" component={withTracker(Home)} />
        <Route exact path="/" component={withTracker(Home)} />

        <App component={withTracker(App)}>
          <Route exact={false} path="/search" component={withTracker(Search)} />
          <Route exact={false} path="/stig" component={withTracker(StigDetailsPage)} />
          <Route exact={false} path="/check" component={withTracker(CheckDetailsPage)} />
        </App>

      </Switch>
    </ConnectedRouter>
  </Provider>
);


Root.propTypes = {
  store: PropTypes.object.isRequired
};


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

render(<Root store={store} />, document.getElementById('root'));


