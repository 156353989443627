import React from "react";
import "./Search.scss";
import SearchBar from "../SearchBar/SearchBar";
import SearchResults from "../SearchResults/SearchResults";
import TagFilterList from "../TagFilterList/TagFilterList";
import { ScrollContainer } from "../ScrollContainer";

const Search = () => (
  <ScrollContainer customCssClass="search">
      <SearchBar />
      <TagFilterList />
      <SearchResults />
  </ScrollContainer>
);

export default Search;
