import React, { Component } from "react";
import PropTypes from "prop-types"
import classnames from "classnames";
import { withToggle } from "../withToggle";
import './Expander.scss';

// :: func => Event => a
export const withoutNavigating = func => event => {
  event && event.preventDefault();
  return func();
};

/**
 * Determine if a DOM element's content is taller than it's height for
 * an element that is not using overflow: visible
 * @param clientHeight
 * @param scrollHeight
 * @returns {boolean}
 */
const isElementOverflowing = ({ clientHeight = 0, scrollHeight = 0 } = {}) =>
  clientHeight < scrollHeight;

/**
 * Expander component wraps children in an expandable container
 */
export class Expander extends Component {
  state = {
    isOverflowing: false
  };

  componentDidMount() {
    this.setState({
      isOverflowing: isElementOverflowing(this.panelNode)
    });
  }

  render() {
    const {
      isToggled,
      toggle,
      children,
      customCssClass
    } = this.props;

    return (
      <div
        className={ classnames("expander", customCssClass, {
          "expander--expanded": isToggled,
          "expander--overflowing": this.state.isOverflowing,
        }) }>
        <div
          ref={ node => {
            this.panelNode = node
          } }
          className="expander-panel">
          { children }
        </div>
        {
          this.state.isOverflowing &&
          <button
             className="expander__button"
             onClick={ withoutNavigating(toggle) }>
            { isToggled ? "show less" : "show more" }
          </button>
        }
      </div>
    )
  }
}

Expander.propTypes = {
  // should the expander be expanded to show the full contents
  isToggled: PropTypes.bool,
  // function that toggles expansion on and off
  toggle: PropTypes.func,
  // the content of the expander
  children: PropTypes.any,
  // custom css class to use
  customCssClass: PropTypes.string
};

export const TogglableExpander = withToggle(Expander);