import "./SearchResults.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { MatchingStigResultList } from "../MatchingStigs/MatchingStigs";
import { setCurrentStig, setCurrentCheck } from "../../actions";
import { withSize } from "react-sizeme";
import { breakpoint, Breakpoints } from "../../common/breakpoints";
import map from "lodash/fp/map";
import { Result } from "../Result/Result";

//Pulled this up here because we use it in the scroll to top HOC below.
// Want to make sure, if it changes, it changes together. magic strings and all...
const containerDivClassName = "search-results";
const noop = () => {};

export const SearchResultList = ({
  searchResults = [],
  searchText = "",
  isBusy = false,
  matchingStigs = [],
  setCurrentStig = noop,
  setCurrentCheck = noop,
  size
}) => {
  const results = map(
    r => (
      <Result
        key={r.stigId}
        result={r}
        // enable clicking card on small screens otherwise pass null to disable
        onCardClicked={breakpoint(size) === Breakpoints.SMALL ? setCurrentCheck : null}
        // enable clicking stig link on large screens otherwise pass null to disable
        onStigClicked={breakpoint(size) === Breakpoints.LARGE ? setCurrentStig  : null}
      />
    ),
    searchResults
  );

  return (
    <div className={containerDivClassName}>
      <div className="search-results__list-wrapper">
        <section className="results">
          <MatchingStigResultList
            stigs={matchingStigs}
            setCurrentStig={setCurrentStig}
          />
          {results}

          {(!results || (results.length === 0 && !isBusy)) && (
            <div className="search-results__no-results-found">
              No results found for{" "}
              <span className="search-results__search-text">{searchText}</span>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

SearchResultList.propTypes = {
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      fix_text: PropTypes.string,
      check_text: PropTypes.string,
      stig_title: PropTypes.string,
      score: PropTypes.string,
      cat: PropTypes.string,
      version: PropTypes.string
    })
  ),
  searchText: PropTypes.string,
  isBusy: PropTypes.bool,
  matchingStigs: PropTypes.arrayOf(PropTypes.string)
};

class SearchResultListComponent extends Component {
  componentDidUpdate() {
    var div = document.getElementsByClassName(containerDivClassName);
    if (div && div[0]) {
      div[0].scrollTop = 0;
    }
  }

  render() {
    return <SearchResultList {...this.props} />;
  }
}

export default connect(
  state => ({
    searchResults: state.searchResults,
    searchText: state.searchText,
    isBusy: state.isBusy,
    matchingStigs: state.matchingStigs
  }),
  {
    setCurrentStig,
    setCurrentCheck
  }
)(withSize()(SearchResultListComponent));
