import React from "react";
import "./StigFilterBar.scss";
import PropTypes from "prop-types";
import search from "../../images/search-icon.svg";

const noop = () => { };

export const StigFilterBar = ({
  setStigFilterText = noop,
  editStigFilterText = noop,
  editedFilterText = "",
  resultCount
}) => {

  function handleChange(e) {
    //set the filter term
    editStigFilterText(e.target.value);
  }

  function handleKeyPress(e) {
    if (e.charCode === 13) {
      executeSearch(e)
    }
  }

  function executeSearch(e) {
    setStigFilterText(editedFilterText)
    //Cause the on screen keyboard to dismiss
    // in mobile by losing focus on the input
    e.target.blur()
  }

  return (
    <section className="filter-panel">
      <div className="filter-form">
        <input
          aria-label="search"
          type="text"
          className="filter-form__input"
          placeholder="enter keywords to search for..."
          onKeyPress={handleKeyPress}
          onChange={handleChange}
          value={editedFilterText}
        />
        <button className="filter-button" onClick={(e) => executeSearch(e)}>
          <img alt="search" src={search} />
        </button>
      </div>
      <div className="filter-result-count">
        {`Showing ${resultCount} results`}
      </div>
      {/*<SelectedFilters />*/}
    </section>
  );
};

StigFilterBar.propTypes = {
  editedFilterText: PropTypes.string,
  setStigFilterText: PropTypes.func,
  editStigFilterText: PropTypes.func,
  resultCount: PropTypes.number,
  filteredResultCount: PropTypes.number
};
