import "./StigDetailsPage.scss";
import React from "react";
import PropTypes from "prop-types";
import { StigResults } from "./StigResults";
import { StigFilterBar } from "./StigFilterBar";
import { CatFilterList } from "../CatFilterList/CatFilterList";
import { connect } from "react-redux";
import {
  editStigFilterText,
  setCurrentCheck,
  setStigFilterText,
  toggleCatFilter
} from "../../actions";
import { getStigTitle } from "../../common/stigUtils";
import { compose, head, get, size } from "lodash/fp";
import classnames from "classnames";
import { ScrollContainer } from "../ScrollContainer";
import { Francisco } from "../Francisco/Francisco";
import { withSize } from "react-sizeme";
import { breakpoint, Breakpoints } from "../../common/breakpoints";

// :: { results: [ a ] } => a
const firstResult = compose(
  head,
  get("results")
);

// :: { results: [ {version} ] } => String
const firstResultVersion = compose(
  get("version"),
  firstResult
);

// :: { results: [ {link} ] } => String
const firstResultLink = compose(
  get("link"),
  firstResult
);

export class StigDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { shrinkHeader: false };
  }

  handleScroll = distanceY => {
    this.setState({ shrinkHeader: distanceY > 200 });
  };

  renderHeader = () => {
    const { details, searchText, currentStig } = this.props;

    return (
      <header
        className={classnames("stig-header", {
          "stig-header--shrunk": this.state.shrinkHeader
        })}
      >
        {searchText && (
          <a
            href={`/search/${searchText}`}
            className="stig-header__back-button"
          >
            back to results for {searchText}
          </a>
        )}
        <h1 className="stig-header__stig-title">{getStigTitle(currentStig)}</h1>
        <a href={firstResultLink(details)} className="download-button" />
        <p className="stig-header__stig-version">
          {firstResultVersion(details)}
        </p>
      </header>
    );
  };

  render() {
    const {
      cats = [],
      editedStigFilterText,
      selectedFilters,
      details,
      stigFilterText,
      searchText,
      currentStig,
      setCurrentCheck,
      ...props
    } = this.props;

    return (
      <section className="stig-details-page">
        <div className="francisco">
          <Francisco />
        </div>
        {this.renderHeader()}
        <section className="stig-details__results-panel">
          <CatFilterList
            cats={cats}
            selectedFilters={selectedFilters}
            toggleFilter={props.toggleCatFilter}
          />
          <ScrollContainer
            onScroll={this.handleScroll}
            customCssClass="stig-details__results"
          >
            <StigFilterBar
              editedFilterText={editedStigFilterText}
              setStigFilterText={props.setStigFilterText}
              editStigFilterText={props.editStigFilterText}
              resultCount={size(get("results", details))}
            />
            <StigResults
              stigFilterText={stigFilterText}
              results={details.results}
              setCurrentCheck={setCurrentCheck}
              showCompact={breakpoint(this.props.size) === Breakpoints.SMALL }
            />
          </ScrollContainer>
        </section>
      </section>
    );
  }
}

StigDetailsPage.propTypes = {
  // TODO: consider sharing this definition with the other components that use it
  details: PropTypes.shape({
    currentStig: PropTypes.string,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        fix_text: PropTypes.string,
        check_text: PropTypes.string,
        stig_title: PropTypes.string,
        score: PropTypes.string,
        cat: PropTypes.string,
        version: PropTypes.string,
        link: PropTypes.string
      })
    )
  }),
  fetching: PropTypes.bool
};

export default connect(
  state => ({
    cats: state.stigDetails.cats,
    editedStigFilterText: state.editedStigFilterText,
    selectedFilters: state.selectedFilters,
    details: state.stigDetails,
    stigFilterText: state.stigFilterText,
    currentStig: state.currentStig,
    searchText: state.searchText
  }),
  {
    toggleCatFilter,
    setStigFilterText,
    editStigFilterText,
    setCurrentCheck
  }
)(withSize()(StigDetailsPage));
