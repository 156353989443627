import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers/rootReducer";
import { routerMiddleware } from 'connected-react-router'

const configureStore = (initialState = {}, history) => {
  const middleware = [];

  // Build the middleware for intercepting and dispatching navigation actions
  middleware.push(thunk);
  middleware.push(routerMiddleware(history));

  if (process.env.NODE_ENV === `development`) {
    const { logger } = require(`redux-logger`);
    middleware.push(logger);
  }

  return createStore(rootReducer(history), initialState, applyMiddleware(...middleware));
};

export default configureStore;
