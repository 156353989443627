import React from 'react';
import './Terms.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import francisco from '../../images/francisco-slate.svg';

export default () => {
  return (
    <div className="app-page">
      <header className="app-page__header">
        <Header/>
        <section className="search-start">
          <div className="francisco">
            <img alt="francisco" src={francisco}/>
          </div>
        </section>
      </header>
      <main className="app-page__main">
        <section className="static-content">
          <div>
              <h1>Terms &amp; conditions</h1>

              <h3>1. Terms</h3>

              <p>By accessing this website, you are agreeing to be bound by these website Terms and Conditions of Use, all applicable laws and regulations, and agree
                that you are responsible for compliance with any applicable local laws. If you do not agree
                with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable
                copyright and trade mark law.</p>

              <h3>2. Use License</h3>

              <ol>
                <li>Permission is granted to temporarily download one copy of the materials (information or software) on Beacon Cloud Solutions's website for
                  personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of
                  title, and under this license you may not:

                  <ol>
                    <li>modify or copy the materials;</li>
                    <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                    <li>attempt to decompile or reverse engineer any software contained on Beacon Cloud Solutions's website;</li>
                    <li>remove any copyright or other proprietary notations from the materials; or</li>
                    <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                  </ol>
                </li>
                <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Beacon Cloud Solutions at any time.
                  Upon terminating your viewing of these materials or upon the termination of this license, you
                  must destroy any downloaded materials in your possession whether in electronic or printed format.
                </li>
              </ol>

              <h3>3. Disclaimer</h3>

              <ol>
                <li>The materials on Beacon Cloud Solutions's website are provided "as is". Beacon Cloud Solutions makes no warranties, expressed or implied, and
                  hereby disclaims and negates all other warranties, including without limitation, implied warranties
                  or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                  Further, Beacon Cloud Solutions does not warrant or make any representations concerning the
                  accuracy, likely results, or reliability of the use of the materials on its Internet website or otherwise relating to such materials or on any sites
                  linked to this site.
                </li>
              </ol>

              <h3>4. Limitations</h3>

              <p>In no event shall Beacon Cloud Solutions or its suppliers be liable for any damages (including, without limitation, damages for loss of data or
                profit, or due to business interruption,) arising out of the use or inability to use the materials
                on Beacon Cloud Solutions's Internet site, even if Beacon Cloud Solutions or a Beacon Cloud Solutions authorized representative has been notified
                orally or in writing of the possibility of such damage. Because some jurisdictions do not allow
                limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>

              <h3>5. Revisions and Errata</h3>

              <p>The materials appearing on Beacon Cloud Solutions's website could include technical, typographical, or photographic errors. Beacon Cloud Solutions
                does not warrant that any of the materials on its website are accurate, complete, or current.
                Beacon Cloud Solutions may make changes to the materials contained on its website at any time without notice. Beacon Cloud Solutions does not,
                however, make any commitment to update the materials.</p>

              <h3>6. Links</h3>

              <p>Beacon Cloud Solutions has not reviewed all of the sites linked to its Internet website and is not responsible for the contents of any such linked
                site. The inclusion of any link does not imply endorsement by Beacon Cloud Solutions of the
                site. Use of any such linked website is at the user's own risk.</p>

              <h3>7. Site Terms of Use Modifications</h3>

              <p>Beacon Cloud Solutions may revise these terms of use for its website at any time without notice. By using this website you are agreeing to be bound
                by the then current version of these Terms and Conditions of Use.</p>

              <h3>8. Governing Law</h3>

              <p>Any claim relating to Beacon Cloud Solutions's website shall be governed by the laws of the State of California without regard to its conflict of law
                provisions.</p>

              <p>General Terms and Conditions applicable to Use of a website.</p>

              <p>We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information
                is protected and maintained.</p>
          </div>
        </section>
      </main>
      <Footer/>
    </div>
  );
};

