import "./CatFilterList.scss";
import CatFilter from "../CatFilter/CatFilter";
import React from "react";
import PropTypes from "prop-types";
import noop from "lodash/fp/noop";

export const CatFilterList = ({
  cats = [],
  selectedFilters = [],
  toggleFilter = noop
}) => {
  const results = cats.map(cat => {
    return (
      <CatFilter
        cat={cat}
        key={cat}
        toggleFilter={toggleFilter}
        isSelected={selectedFilters.indexOf(cat) > -1}
      />
    );
  });

  return (
    <div className="cat-filters">
      <ul className="cat-filters__list">{results}</ul>
    </div>
  );
};

CatFilterList.propTypes = {
  cats: PropTypes.arrayOf(PropTypes.string),
  selectedFilters: PropTypes.arrayOf(PropTypes.string)
};
