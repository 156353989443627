import './SearchResultCount.scss';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const SearchResultCount = ( { searchResults = [] } ) => {

  if (!searchResults || searchResults.length === 0){
    return null
  }

  return (
    searchResults.length >= 100
      ?  <div className="search-result-count">Showing top 100 results</div>
      : <div className="search-result-count">Found {searchResults.length} {searchResults.length === 1 ? 'result' : 'results'}</div>
  );
};

SearchResultCount.propTypes = {
  searchResults: PropTypes.arrayOf( PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    fix_text: PropTypes.string,
    check_text: PropTypes.string,
    stig_title: PropTypes.string,
    score: PropTypes.string,
    cat: PropTypes.string
  })),
};



export default connect(state => ({ searchResults: state.searchResults}), {})(SearchResultCount);

