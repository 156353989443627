import './TagFilterList.scss';
import TagFilter from '../TagFilter/TagFilter';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const TagFilterList = ({ tags = [], selectedFilters = [] }) => {

  const results = tags.map(tag => {
    return (
      <TagFilter tag={tag} key={tag} isSelected={selectedFilters.indexOf(tag) > -1} />
    );
  });

  return (
    <div className="tag-filters">
      <ul className="tag-filters__list">
        {results}
      </ul>
    </div>
  );
};

TagFilterList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  selectedFilters: PropTypes.arrayOf(PropTypes.string)
};


export default connect(state => ({ tags: state.searchTags, selectedFilters: state.selectedFilters }))(TagFilterList);

