import React, { Component } from "react";

function getDisplayName(Component) {
  return (
    Component.displayName ||
    Component.name ||
    (typeof Component === 'string' && Component.length > 0 ? Component :
      'Unknown')
  );
}

/**
 * This is a Higher Order Component that adds a click handler to toggle
 * a prop sent to the wrapped component
 * @param WrappedComponent
 */
export function withToggle(WrappedComponent) {
  return class extends Component {
    static displayName = `withToggle(${getDisplayName(WrappedComponent)})`;

    state = {
      isToggled: false,
    };

    toggle = () => {
      this.setState({ isToggled: !this.state.isToggled });
    };

    render() {
      return (
        <div className="toggle-container">
          <WrappedComponent
            isToggled={this.state.isToggled}
            toggle={this.toggle}
            {...this.props}
          />
        </div>
      );
    }
  };
}