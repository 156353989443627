import React from 'react';
import PropTypes from "prop-types";
import './Checkbox.css';
import classnames from 'classnames';

export const Checkbox = ({ isChecked, isHovered }) => (
  <div className={ classnames("checkbox", {
    "checkbox--checked": isChecked,
    "checkbox--unchecked": !isChecked,
    "checkbox--hover": isHovered
  }) }>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.54 14.09">
      <g className="checkbox__group">
        <path className="checkbox__box" d="M11,9.94a.83.83,0,0,1-.33.63L6.13,13.5a.67.67,0,0,1-.67,0L.86,10.61A.84.84,0,0,1,.52,10l0-5.83a.83.83,0,0,1,.33-.63L5.41.59a.67.67,0,0,1,.67,0l4.6,2.88A.84.84,0,0,1,11,4.1Z"/>
        <path className="checkbox__check" d="M9.52,9.1a.61.61,0,0,1-.24.45L6,11.63a.51.51,0,0,1-.48,0L2.28,9.58A.59.59,0,0,1,2,9.14V5a.55.55,0,0,1,.23-.44L5.51,2.46a.46.46,0,0,1,.48,0l3.27,2A.61.61,0,0,1,9.5,5Z"/>
      </g>
    </svg>
  </div>
);

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  isHovered: PropTypes.bool
};

export default Checkbox;