import "./MatchingStigs.scss";
import React from "react";
import PropTypes from "prop-types";
import { Highlighter } from "../Highlighter/Highlighter";
import { dehighlight } from "../highlightUtils";
import { getTitleNoStig } from "../../common/stigUtils";

const noop = () => {};

export const MatchingStigResultList = ({
  stigs = [],
  setCurrentStig = noop
}) => {

  function onTitleClicked(title) {
    const cleanTitle = dehighlight(title)
    setCurrentStig(cleanTitle);
  }

  const results = stigs.map(r => {
    return (
      <div className="matching-stig" key={r}>
        <button className="link-button" onClick={() => onTitleClicked(r)}>
          <Highlighter text={getTitleNoStig(r)} />
        </button>
      </div>
    );
  });

  return results.length === 0 ? null : (
    <div className="matching-stigs">
      <div>Matching stigs</div>
      <section className="matching-stigs__results">{results}</section>
    </div>
  );
};

MatchingStigResultList.propTypes = {
  stigs: PropTypes.arrayOf(PropTypes.string)
};
