import { version } from "../../../package.json";

import React from "react";
import "./Footer.scss";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { Francisco } from "../Francisco/Francisco";
import { Link } from "react-router-dom";

export const Footer = ({ lastScrapeDate = "" }) => (
  <div className="app-footer">
    <div className="francisco">
      <Link to="/">
        <Francisco />
      </Link>
    </div>
    <p>Build {version}</p>
    {lastScrapeDate !== "" && (
      <p className="scrape-date">STIGS Last updated {lastScrapeDate}</p>
    )}
    <p>
      © {new Date().getFullYear()} <a target="_blank" href="https://www.beaconcloudsolutions.com/">Beacon Cloud Solutions</a>, Inc. All rights
      reserved
    </p>
    <nav>
      <ul className="app-footer__menu">
        <li className="app-footer__menu-item">
          <a href="/privacy">Privacy</a>
        </li>
        <li className="app-footer__menu-item">
          <a href="/terms">Terms</a>
        </li>
        <li className="app-footer__menu-item">
          <a href="mailto:info@beaconcloudsolutions.com">Contact</a>
        </li>
      </ul>
    </nav>
  </div>
);

Footer.propTypes = {
  lastScrapeDate: PropTypes.string
};

export default connect(
  state => ({
    lastScrapeDate: state.lastScrapeDate
  })
)(Footer);
