import React, { Component } from "react";
import PropTypes from "prop-types";
import { hideAbout } from "../../actions";
import { connect } from "react-redux";
import noop from "lodash/fp/noop";
import { showAbout } from "../../reducers/rootReducer";
import classnames from "classnames";
import "./About.scss";
import { version } from "../../../package.json";
import HexFrame from "../HexFrame/HexFrame"

export const About = ({showAbout = false}) => {
    return (<div className={classnames("about", { "about--visible": showAbout })}>
        <HexFrame />
        <div className="about__content">
          <h1>About STIGHUB</h1>
          <p><b>STIGHUB</b> is an easy to use Security Technical
            Implementation
            Guide
            (STIG) search tool.</p>
          <p><b>STIGHUB</b> allows you to quickly search through the entire
            STIG
            library
            (excluding PKI protected STIGS) by keyword or phrase.</p>
          <p><b>STIGHUB</b> updates nightly ensuring that the most recent
            published
            STIG’s
            from IASE are available for searching.</p>
          <span className="about__build">Build {version}</span>
        </div>
      </div>
    );
}


About.propTypes = {
  hideAbout: PropTypes.func,
  showAbout: PropTypes.bool
};

export class AboutWrapper extends Component {
  componentDidMount() {
    document.addEventListener("click", this.handleClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, true);
  }

  handleClick = () => {
    if (this.props.showAbout) {
      (this.props.hideAbout || noop)()
    }
  };

  render(){
    return (
      <About {...this.props} />
    )
  }

}


export default connect(
  state => ({
    showAbout: showAbout(state)
  }), {
    hideAbout
  }
)(AboutWrapper)