import { combineReducers } from "redux";

import split from "lodash/fp/split";
import last from "lodash/fp/last";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import compose from "lodash/fp/compose";
import compact from "lodash/fp/compact";
import { connectRouter } from 'connected-react-router'

import {
  searchText,
  searchResults,
  searchTags,
  selectedFilters,
  editedSearchText,
  editedStigFilterText,
  isBusy,
  lastScrapeDate,
  matchingStigs,
  currentStig,
  currentCheck,
  stigDetails,
  stigFilterText,
  fetching,
  signup
} from "./index";
import { filterByPropText } from "../common/arrayUtils";

export default (history) => combineReducers({
  router: connectRouter(history),
  searchText,
  editedSearchText,
  editedStigFilterText,
  searchResults,
  searchTags,
  selectedFilters,
  isBusy,
  lastScrapeDate,
  matchingStigs,
  currentStig,
  currentCheck,
  stigDetails,
  stigFilterText,
  fetching,
  signup
});

// URL => String
const urlLastPart = compose(
  last,
  split("/")
);

export const showAbout = state =>
  urlLastPart(get("router.location.pathname", state)) === 'about';

export const showSignup = state =>
  urlLastPart(get("router.location.pathname", state)) === 'signup';

export const getResults = state => getOr([], "stigDetails.results", state);

export const getFilteredResults = state => {
  const results = getResults(state);
  const filterText = get("stigFilterText", state);
  return filterByPropText("title", filterText)(results);
};

export const getSelectedCheckId = state => {
  const [resource, id] = compact(split("/", state.router.location.pathname));
  return id
}