import React from "react";
import "./Newsletter.scss";
import { connect } from "react-redux";
import { hideSignup, signup } from "../../actions";
import { showSignup } from "../../reducers/rootReducer";
import { noop, isEmpty } from "lodash/fp";
import PropTypes from "prop-types";
import HexFrame from "../HexFrame/HexFrame";
import classnames from "classnames";

export class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", name: "" };
    this.node = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, true);
  }

  handleClick = e => {
    if (this.props.showSignup && !this.node.current.contains(e.target)) {
      (this.props.hideSignup || noop)();
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSignup = e => {
    this.props.signup(this.state.name, this.state.email);
  };

  render() {
    const { showSignup = false, isFetching, isSignedUp, error } = this.props;

    const hasError = !isEmpty(error);

    return (
      showSignup && (
        <div
          ref={this.node}
          className={classnames("newsletter", {
            "newsletter--visible": showSignup,
            "newsletter--fetching": isFetching,
            "newsletter--signed-up": isSignedUp,
            "newsletter--error": hasError
          })}
        >
          <HexFrame />
          <div className="newsletter__content">
            <h2>Sign up for security news, alerts, and advice</h2>
            <div className="newsletter__interface">
              <div className="newsletter__signup-form">
                <div className="newsletter__fields">
                  <input
                    type="email"
                    placeholder="email address"
                    name="email"
                    onChange={e => this.handleChange(e)}
                    value={this.state.email}
                  />
                  <input
                    type="name"
                    placeholder="name"
                    name="name"
                    onChange={e => this.handleChange(e)}
                    value={this.state.name}
                  />
                </div>
                <div className="newsletter__actions">
                  <button
                    onClick={e => this.handleSignup(e)}
                    value="signup"
                    name="signup"
                    className="signup-button"
                  >
                    {isFetching ? "Signing up..." : "Sign up"}
                  </button>
                  <button
                    onClick={e => this.props.hideSignup(e)}
                    value="cancel"
                    name="cancel"
                    className="cancel"
                  >
                    Close
                  </button>
                </div>
              </div>
              {isSignedUp && (
                <div className="newsletter__confirmation">
                  <div className="newsletter__success-message">
                    <h3>Congratulations</h3>
                    <p>You are now signed up for the STIGHUB newsletter.</p>
                  </div>
                  <div className="newsletter__actions">
                    <button
                      onClick={e => this.props.hideSignup(e)}
                      value="cancel"
                      name="cancel"
                      className="newsletter__close-confirmation-button"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
              {hasError && (
                <div className="newsletter__error">
                  <div className="newsletter__error-message">
                    <h3>Hmm.</h3>
                    <p>
                      That didn't work and it's not clear what the problem is.
                    </p>
                  </div>
                  <div className="newsletter__actions">
                    <button
                      onClick={e => this.handleSignup(e)}
                      value="try again"
                      name="signup"
                      className="signup-button"
                    >
                      Try again
                    </button>
                    <button
                      onClick={e => this.props.hideSignup(e)}
                      value="cancel"
                      name="cancel"
                      className="cancel"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

Newsletter.propTypes = {
  hideSignup: PropTypes.func,
  showSignup: PropTypes.bool,
  /** true if the user has submitted sign up request and is waiting to hear if its successful **/
  isFetching: PropTypes.bool,
  /** true if the user was successfully signed up **/
  isSignedUp: PropTypes.bool,
  /** error message if the sign up failed, otherwise nil **/
  error: PropTypes.string
};

export default connect(
  state => ({
    showSignup: showSignup(state),
    isFetching: state.signup.fetching,
    isSignedUp: state.signup.complete,
    error: state.signup.error
  }),
  {
    hideSignup,
    signup
  }
)(Newsletter);
