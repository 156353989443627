import React from 'react';
import './App.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import BusyIndicator from "../BusyIndicator/BusyIndicator";

const App = ({children}) => (
  <div className="app-page">
    <div className="app-page__header">
      <Header/>
      <BusyIndicator/>
    </div>
    <div className="app-page__main">
        {children}
    </div>
    <Footer/>
  </div>
);

export default App;
